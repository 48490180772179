import { ITenant, ITenantDB, TTenantField } from './ITenant';
import { IUIConfig } from '../../components/general/theme/ComputedStyles/interfaces/computedStyles.interface';
import { defaultTenantTheme } from '../../configs/defaultTenant';

const defaultTheme = defaultTenantTheme();
export const mapUiConfigFromDBToThemeFE = (uiConfigString: string): IUIConfig => {
    const uiConfig = JSON.parse(uiConfigString) as IUIConfig;

    return {
        ...uiConfig,
        colors: {
            ...defaultTheme.colors,
            ...uiConfig.colors,
            background: { ...defaultTheme.colors?.background, ...uiConfig.colors?.background },
        },
        navigation: {
            ...defaultTheme.navigation,
            ...uiConfig.navigation,
        },
    };
};

export const mapTenantFromDBToFE = (tenant: ITenantDB): ITenant => {
    const fieldsMapped: TTenantField | undefined = tenant.fields?.reduce((acc, field) => {
        if (field.name && field.value) {
            const valueStringOrBoolean =
                field.value === 'true' || field.value === 'false'
                    ? field.value === 'true'
                    : field.value;

            acc[field.name as string] = valueStringOrBoolean;
        }
        return acc;
    }, {} as TTenantField);

    const stockWarningRecipientsAsCSVFromArray = tenant.stockWarningRecipients?.join(',') ?? '';

    // Sort languages by locale (language first ASC (de-DE === de first)
    // Always put the default language on top
    const sortedLanguages = tenant.languages
        ? [...tenant.languages].sort((a, b) => {
              // If one is the default language, it goes first
              if (a.default) return -1;
              if (b.default) return 1;

              const aBase = a.languageCode.split('-')[0];
              const bBase = b.languageCode.split('-')[0];

              // Sort by base language code first
              if (aBase && bBase && aBase !== bBase) {
                  return aBase.localeCompare(bBase);
              }

              // If base languages are the same, sort by full locale
              return a.languageCode.localeCompare(b.languageCode);
          })
        : [];

    return {
        id: tenant.id,
        name: tenant.name,
        url: tenant.url, // deprecated
        domains: tenant.domains,
        phone: tenant.phone,
        fields: fieldsMapped,
        use2FAMail: tenant.use2FAMail,
        fax: tenant.fax,
        uiConfig: tenant.uiConfig ? mapUiConfigFromDBToThemeFE(tenant.uiConfig) : undefined,
        emailSender: tenant.emailSender ?? '',
        sendgridBasicTemplateID: tenant.sendgridBasicTemplateID ?? '',
        sendgridApiKey: tenant.sendgridApiKey ?? '',
        sendgridOrderConfirmationTemplateID: tenant.sendgridOrderConfirmationTemplateID ?? '',
        sendgridRequestNewEmailTemplateID: tenant.sendgridRequestNewEmailTemplateID ?? '',
        sendgridRequestPasswordResetTemplateID: tenant.sendgridRequestPasswordResetTemplateID ?? '',
        sendgridOrder2FATemplateID: tenant.sendgridOrder2FATemplateID ?? '',
        languages: sortedLanguages,
        translations: tenant.translations,
        printformerURL: tenant.printformerURL,
        printformerApiKey: tenant.printformerApiKey,
        printformerIdentifier: tenant.printformerIdentifier,
        orderWorkflowRequireProfileComplete: tenant.orderWorkflowRequireProfileComplete,
        stockWarningRecipients: stockWarningRecipientsAsCSVFromArray,
        deeplAPIKey: tenant.deeplAPIKey ?? '',
        deeplFormality: tenant.deeplFormality ?? '',
        deeplGlobalContext: tenant.deeplGlobalContext ?? '',
        deeplTranslationActive: tenant.deeplTranslationActive ?? false,
    };
};
