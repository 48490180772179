module.exports = {
    mode: 'jit',
    content: [
        './src/**/*.tsx',
        './pages/**/*.{js,ts,jsx,tsx}',
        './components/**/*.{js,ts,jsx,tsx}',
    ],
    darkMode: 'class',
    theme: {
        screens: {
            xs: '460px', // Larger Phones
            sm: '640px', // => @media (min-width: 640px) { ... }
            md: '768px', // => @media (min-width: 768px) { ... }
            lg: '1152px', // => @media (min-width: 1024px) { ... }
            xl: '1280px', // => @media (min-width: 1280px) { ... }
            '2xl': '1488px', // => @media (min-width: 1536px) { ... }
            desktop: '1025px', // => @media (min-width: 1025px) { ... }
        },

        extend: {
            spacing: {
                headerTotalDefault: '110px',
                headerTotalMd: '120px',
                headerUpperDefault: '50px',
                headerUpperMd: '70px',
                headerLowerDefault: '45px',
                headerLowerMd: '50px',
                childrenWrapperPaddingTopDefault: '36px',
                childrenWrapperPaddingTopMd: '42px',
            },
            fontFamily: {
                sans: ['Inter', 'sans-serif'],
                light: ['Inter', 'sans-serif'],
                serif: ['Inter', 'sans-serif'],
                bold: ['inter', 'sans-serif'],
                body: ['Inter', 'sans-serif'],
                display: ['Inter', 'sans-serif'],
                mono: ['Roboto Mono', 'monospaced'],
            },
            colors: {
                cloudbar: {
                    DEFAULT: '#119DA4',
                    light: '#4cdde5',
                    white: '#ffffff',
                },
                cloudbarPrimary: {
                    DEFAULT: '#119DA4',
                    light: '#4cdde5',
                },
                cloudbarSecondary: {
                    DEFAULT: '#C1FF72',
                    light: '#C1FF72',
                },
                primary: {
                    DEFAULT: '#003646',
                    light: '#6B91A2',
                    dark: '#002C57',
                },
                secondary: {
                    DEFAULT: '#00A023',
                    light: '#11d93d',
                    dark: '#036b19',
                },
                success: {
                    DEFAULT: '#00A023',
                    light: '#76C846',
                    dark: '#237021',
                },
                danger: {
                    DEFAULT: '#E90000',
                    light: '#ffcbc6',
                    dark: '#9F0912',
                },
                labels: {
                    DEFAULT: '#003646',
                },
                // Figma: text- is body- here
                body: {
                    DEFAULT: '#343434',
                    light: '#A3A3A3',
                    dark: '#2F2C31',
                    invert: '#FFFFFF',
                    danger: '#E90000',
                    disabled: '#727272',
                },
                text: {
                    light: '#B0B0B0',
                    default: '#343434',
                },
                border: {
                    DEFAULT: '#C5C5C5',
                    light: '#E0E0E0',
                },
                background: {
                    light: '#F9F9F9',
                    dark: '#B4B4B4',
                    lightIntense: '#E8E8E8',
                    primary: '#119DA4',
                    primaryLight: '#4CDDE5',
                    primaryLightHover: '#4CDDE599',
                    primaryDark: '#002C57',
                    secondary: '#00A023',
                    secondaryHover: '#00A02399',
                    danger: '#E90000',
                    dangerHover: '#E9000099',
                    success: '#00A023',
                    successHover: '#00A02399',
                    neutral: '#D9D9D9',
                    neutralHover: '#D9D9D999',
                    disabled: '#D9D9D9',
                    disabledHover: '#D9D9D999',
                    info: '#a1c6ff',
                    infoHover: '#a1c6ff99',
                },
                invert: {
                    primary: '#ffffff',
                    primaryLight: '#ffffff',
                    secondary: '#333333',
                    danger: '#ffffff',
                    success: '#ffffff',
                    neutral: '#333333',
                    disabled: '#999999',
                    info: '#ffffff',
                    dark: '#FFFFFF',
                },
                applications: {
                    cart: {
                        itemBackgroundSelected: '#f8faff',
                    },
                    // test: {
                    //     DEFAULT: '[[primary.DEFAULT]]',
                    //     light: '#565656',
                    // },
                    infoBox: {
                        background: {
                            DEFAULT: '#f0f6ff',
                        },
                        text: {
                            DEFAULT: '#3b82f6',
                        },
                    },
                    header: {
                        background: {
                            DEFAULT: '#ffffff',
                        },
                        text: {
                            userContextPrimary: '#003646',
                            userContextLight: '#a3a3a3',
                        },
                        border: {
                            DEFAULT: '#C5C5C5',
                        },
                    },
                    headerLogo: {
                        text: {
                            companyName: '#003646',
                            claim: '#a3a3a3',
                        },
                    },
                    navigation: {
                        background: {
                            DEFAULT: '#f9f9f9',
                            subHeader: '#f9f9f9',
                            sideSection: '#f9f9f9',
                        },
                        text: {
                            topMenuItems: '#003646',
                            subMenuItems: '#003646',
                            sideMenuItems: '#ffffff',
                        },
                        border: {
                            DEFAULT: '#999999',
                        },
                    },
                    headerSearch: {
                        background: {
                            DEFAULT: '#ffffff',
                        },
                        text: {
                            DEFAULT: '#003646',
                            placeholder: '#dedede',
                        },
                        border: {
                            DEFAULT: '#999999',
                        },
                    },
                },
            },
        },
    },
    variants: {
        extend: {},
    },
    plugins: [
        require('@tailwindcss/typography'),
        require('@tailwindcss/aspect-ratio'),
        require('@tailwindcss/forms'),
    ],
    safelist: ['max-w-6xl', 'max-w-3xl', 'pb-5'],
};
