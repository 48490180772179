import { useApi } from '../api/useApi';
import { useQuery } from '@tanstack/react-query';
import { ITenantDB } from '../../../../interfaces/user/ITenant';
import { gql } from 'graphql-request';
import { mapTenantFromDBToFE } from '../../../../interfaces/user/mapTenant';

export const useTenantCurrentQuery = () => {
    const { postGql } = useApi();
    return useQuery({
        staleTime: 60000,
        queryKey: ['tenantCurrent'],
        queryFn: async () => {
            const { tenantCurrent } = await postGql<{ tenantCurrent: ITenantDB }>(
                'tenantCurrent',
                gql`
                    query {
                        tenantCurrent {
                            id
                            name
                            fields {
                                name
                                value
                            }
                            emailSender
                            sendgridApiKey
                            sendgridBasicTemplateID
                            sendgridOrderConfirmationTemplateID
                            sendgridRequestNewEmailTemplateID
                            sendgridRequestPasswordResetTemplateID
                            stockWarningRecipients
                            uiConfig
                            use2FAMail
                            languages {
                                languageCode
                                default
                            }
                            printformerURL
                            printformerIdentifier
                            printformerApiKey
                            orderWorkflowRequireProfileComplete
                            deeplTranslationActive
                            deeplGlobalContext
                            deeplFormality
                            deeplAPIKey
                        }
                    }
                `
            );

            return mapTenantFromDBToFE(tenantCurrent);
        },
    });
};
