import React, { PropsWithChildren } from 'react';

import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import { useThemeStyles } from '../../theme/TenantTheme/hooks/useThemeStyles';
import { useTranslationApply } from '../../Translations/hooks/useTranslationApply';
import { useTranslation } from '../../Translations/hooks/useTranslation';

export interface ILabel extends PropsWithChildren {
    required?: boolean;
    testId?: string;
    translationKey?: string;
}

export const Label: React.FC<ILabel> = (props) => {
    const { children, required, testId, translationKey } = props;
    const tenantTheme = useRecoilValue(getTenantTheme);

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const { applyStyles } = useThemeStyles();

    return (
        <>
            <div
                className="relative h-max w-full text-sm font-light uppercase leading-6 tracking-wider text-body-light"
                style={{ color: tenantTheme.colors.body.light }}
            >
                <div className="relative w-full text-xs font-medium lg:text-xs">
                    <span
                        className="relative flex flex-row gap-[1px]"
                        data-test-id={`${testId ?? ''}-${required ? 'labelRequired' : 'labelNotRequired'}`}
                        {...applyTranslation({
                            key: translationKey,
                        })}
                        style={{
                            ...applyStyles({
                                textColor: 'colors.labels.DEFAULT',
                            }),
                        }}
                    >
                        {(translationKey && getT(translationKey)) ?? children}
                        {required && (
                            <span
                                className="relative align-super -top-0.5"
                                data-test-id="label-asterisk"
                                style={{ color: tenantTheme.colors.danger.DEFAULT }}
                            >
                                *
                            </span>
                        )}
                    </span>
                </div>
            </div>
        </>
    );
};
