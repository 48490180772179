import React, { PropsWithChildren } from 'react';
import { nanoid } from 'nanoid';

import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import { ExclamationCircleIcon } from '@heroicons/react/16/solid';

export interface IValidationMessage extends PropsWithChildren {
    message: string | undefined;
    testId?: string;
}

export const ValidationMessage: React.FC<IValidationMessage> = (props) => {
    const { message, testId } = props;
    const tenantTheme = useRecoilValue(getTenantTheme);

    return (
        <>
            <div
                className="relative col-span-2 mt-1 flex h-full w-full flex-row items-center gap-2 pl-1 text-xs leading-6 text-danger"
                style={{ color: tenantTheme.colors.danger.DEFAULT }}
            >
                <ExclamationCircleIcon
                    className="h-4 w-4 text-danger"
                    style={{ color: tenantTheme.colors.danger.DEFAULT }}
                />
                <span
                    className="h-max w-full"
                    data-test-id={`${testId ?? nanoid()}-validationMessage`}
                >
                    {message ?? ''}
                </span>
            </div>
        </>
    );
};
