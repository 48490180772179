import { IUIConfig } from '../components/general/theme/ComputedStyles/interfaces/computedStyles.interface';
import tailwindConfig from '../../tailwind.config';

export const defaultTenantTheme = (): IUIConfig => {
    return {
        brand: {
            logo: {
                url: '/assets/logos/logoBlank.svg',
                alt: 'Logo',
                width: 58,
                height: 58,
            },
            companyName: 'FirmennameDefault',
            claim: 'Optionaler Claim',
            pageTitle: 'Cloudbar',
        },
        border: {
            width: 'off',
            radius: 'off',
        },
        // Controlled in tailwind.config
        colors: { ...(tailwindConfig.theme.extend.colors as IUIConfig['colors']) },
        header: {
            showSearch: true,
            showUserDetails: true,
            showAvatar: true,
            showClaim: true,
            showCompanyName: true,
            showFavorites: true,
            showLogo: true,
            showNotifications: true,
            showWideLogoHeader: false,
            showWideLogoLogin: false,
        },
        navigation: {
            showFilter: false,
            editorsShowCategoryNavigation: true,
            editorsHeaderFullWith: false,
        },
    };
};
