import { ITenant } from '../../../interfaces/user/ITenant';
import { atom, DefaultValue, selector } from 'recoil';
import { IAdminMultiSelectItem } from '../controls/adminMultiSelect/AdminMultiSelectItem';
import { ISelectOption } from '../controls/select/Select';
import { IUIConfig } from '../theme/ComputedStyles/interfaces/computedStyles.interface';

export interface ITenantState {
    tenant: ITenant | null;
    tenantsList: ITenant[] | null;
    theme: IUIConfig | null;
    lastUpdate: string;
}

export const tenantStateRecoil = atom<ITenantState>({
    key: 'tenant-state-state',
    default: {
        tenant: null,
        tenantsList: [],
        theme: null,
        lastUpdate: new Date().toISOString(),
    },
});

export const tenantSelector = selector<ITenant | null>({
    key: `tenant`,
    get: ({ get }): ITenant | null => {
        return get(tenantStateRecoil).tenant;
    },
    set: ({ set }, newValue) =>
        set(
            tenantStateRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, tenant: newValue };
                  }
        ),
});

export const tenantIDSelector = selector<string>({
    key: `tenantId`,
    get: ({ get }): string => {
        return get(tenantStateRecoil).tenant?.id ?? '';
    },
});

export const tenantNameSelector = selector<string>({
    key: `tenantName`,
    get: ({ get }): string => {
        return get(tenantStateRecoil).tenant?.name ?? '';
    },
});

export const tenantsListSelector = selector<ITenant[]>({
    key: `tenantsList`,
    get: ({ get }): ITenant[] => {
        return get(tenantStateRecoil).tenantsList ?? ([] as ITenant[]);
    },
    set: ({ set }, newValue) =>
        set(
            tenantStateRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, tenantsList: newValue };
                  }
        ),
});

export const getTenantsMultiSelectOptions = selector<IAdminMultiSelectItem[]>({
    key: `get-tenants-multi-select-options`,
    get: ({ get }): IAdminMultiSelectItem[] => {
        const tenants = get(tenantStateRecoil).tenantsList;

        const options: IAdminMultiSelectItem[] = [];

        tenants?.forEach((tenant) => {
            const option = {
                id: tenant.id,
                value: tenant.name,
                label: tenant.name,
            } as IAdminMultiSelectItem;

            options.push(option);
        });

        return options;
    },
});

export const getTenantsSelectOptions = selector<ISelectOption[]>({
    key: `get-tenants-select-options`,
    get: ({ get }): ISelectOption[] => {
        const tenants = get(tenantStateRecoil).tenantsList;

        const options: ISelectOption[] = [];

        tenants?.forEach((tenant) => {
            const option = {
                id: tenant.id,
                name: tenant.name,
            } as ISelectOption;

            options.push(option);
        });

        return options;
    },
});

// last Update
export const lastUpdateSelector = selector<string>({
    key: `last-update`,
    get: ({ get }): string => {
        return get(tenantStateRecoil).lastUpdate;
    },
    set: ({ set }, newValue) =>
        set(
            tenantStateRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, lastUpdate: newValue };
                  }
        ),
});
