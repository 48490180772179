import { useSetRecoilState } from 'recoil';
import { tenantSelector } from '../../state/tenantState';
import { useTenantCurrentQuery } from './useTenantCurrentQuery';
import { useEffect } from 'react';
import { useLocalisationState } from '../../Translations/state/localisation.state';

export const useTenantSelf = () => {
    const setTenantCurrent = useSetRecoilState(tenantSelector);
    const { updateTenantDefaultLanguage, updateUserLanguageOptions } = useLocalisationState();

    const { data: tenant, refetch, isFetching } = useTenantCurrentQuery();

    useEffect(() => {
        if (tenant) {
            const currentTheme = localStorage.getItem('theme');
            const newTheme = tenant.uiConfig;

            const themesAreEqual = currentTheme === JSON.stringify(newTheme);

            if (!themesAreEqual) {
                localStorage.removeItem('theme');
            }

            if (tenant.languages) {
                updateUserLanguageOptions(tenant.languages);
                const defaultLanguage = tenant.languages.find((el) => el.default);
                if (defaultLanguage) {
                    updateTenantDefaultLanguage(defaultLanguage);
                }
            }

            setTenantCurrent(tenant);
        }
    }, [setTenantCurrent, tenant, updateTenantDefaultLanguage, updateUserLanguageOptions]);

    return { refetch, tenant, isFetching };
};
